import Checkbox from '@components/Checkbox';
import PopupSearchable from '@components/PopupSearchable';
import IconArrowDown2 from '@icons/IconArrowDown2';
import IconCancel12 from '@icons/IconCancel12';
import { productService } from '@services/productService';
import { escapesValue, kmpSearch } from '@utils/common';
import constant from '@utils/constant';
import { getProductsSelector } from '@utils/selectors';
import ContentLoading from '@views_admin/ContentLoading';
import { Dropdown } from 'antd';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const _searchInOptionList = (optionList, searchValue) => {
   let results = [];
   optionList.forEach((option) => {
      if (kmpSearch(searchValue, escapesValue(option.name)) !== -1) {
         results = [...results, { ...option }];
      }
   });
   return results;
};

const SelectProducts = memo(({ value, disabled, onChange }) => {
   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
   const [searchValue, setSearchValue] = useState('');
   const [submitting, setSubmitting] = useState(false);
   const innerRef = useRef();
   const productsCanceler = useRef();

   useEffect(() => {
      const loadProducts = async () => {
         productsCanceler.current = await productService.loadProducts({
            page: 1,
            page_size: constant.defaultPagesize,
            order_by: 'updated_at',
            sort_type: 'DESC',
         });
      };
      loadProducts();
      return () => {
         if (productsCanceler.current) {
            productsCanceler.current.cancel();
         }
      };
   }, []);

   const handleSelectOption = useCallback(
      (uid, name) => {
         if (value[uid]) {
            const newValue = { ...value };
            delete newValue[uid];
            onChange({ ...newValue });
         } else {
            onChange({
               ...value,
               [uid]: {
                  uid,
                  name,
               },
            });
         }
      },
      [onChange, value]
   );

   const handleDropdownVisibleChange = useCallback((e) => {
      setIsDropdownOpen(e);
   }, []);

   const deleteOption = (uid) => {
      const newValue = { ...value };
      delete newValue[uid];
      onChange({ ...newValue });
   };

   const popupStyles = (() => {
      if (innerRef.current) {
         const rect = innerRef.current.getBoundingClientRect().width;
         return {
            width: `${rect}px`,
            maxWidth: `${rect}px`,
         };
      }
      return {};
   })();

   const menu = (() => {
      return (
         <PopupSearchable
            className="pricing-types-dropdown"
            style={popupStyles}
            isOpen={isDropdownOpen}
            scrollContentClassName="pricing-types-dropdown__option-list"
            searchValue={searchValue}
            dataSelector={getProductsSelector}
            setSearchValue={setSearchValue}
            funcSearchInOptionList={_searchInOptionList}
            funcFetchData={productService.loadProducts}
            disabled={disabled}
         >
            {({ optionList, loading }) => (
               <>
                  <ContentLoading isLoading={loading || submitting} size="small" />
                  {optionList.length === 0 && !loading && (
                     <div className="empty">No data</div>
                  )}
                  {optionList.map((option) => {
                     const isSelected = value?.[option.uid] ? true : false;
                     const optionDisabled = loading;
                     return (
                        <div
                           className={`popup-searchable__item-option multiple ${
                              isSelected ? 'selected' : ''
                           }`}
                           key={option.uid}
                           onClick={() =>
                              !optionDisabled &&
                              !disabled &&
                              handleSelectOption(option.uid, option.name)
                           }
                        >
                           <Checkbox
                              label={option.name}
                              colorActive="#2F80ED"
                              isChecked={isSelected}
                              readOnly={true}
                              disabled={optionDisabled}
                           />
                        </div>
                     );
                  })}
               </>
            )}
         </PopupSearchable>
      );
   })();

   const optionList = useMemo(() => {
      return Object.values(value);
   }, [value]);

   console.log(optionList);

   return (
      <Dropdown
         overlayClassName="common-dropdown size-small"
         visible={isDropdownOpen}
         overlay={menu}
         placement="bottomLeft"
         trigger="click"
         onVisibleChange={handleDropdownVisibleChange}
         overlayStyle={popupStyles}
      >
         <div
            className={`fake-input-select ${disabled ? 'disabled' : ''}`}
            ref={innerRef}
         >
            {optionList.length === 0 ? (
               <span className="placeholder">Select products</span>
            ) : (
               <div className="fake-input-select__selected-options">
                  {optionList.map((op) => (
                     <div className="op-item" key={op.uid}>
                        <div className="op-item--content">
                           <span>{op.name}</span>
                           {!disabled && (
                              <i
                                 onClick={(e) => {
                                    e.stopPropagation();
                                    deleteOption(op.uid);
                                 }}
                              >
                                 <IconCancel12 />
                              </i>
                           )}
                        </div>
                     </div>
                  ))}
               </div>
            )}
            <i className="chevron">
               <IconArrowDown2 />
            </i>
         </div>
      </Dropdown>
   );
});

export default SelectProducts;
