import constant from '@utils/constant';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { createCancelToken } from '@apis/ServiceController';
import RequestModel from '@apis/models/RequestModel';
import { userService } from '@services/userService';
import ContentLoading from '@views_admin/ContentLoading';
import Search from '../../../views_admin/layout/Search';
import CommonTable from '@views_admin/components/CommonTable';
import IconAdd2 from '@icons/IconAdd2';
import { UserStatus } from '@utils/enums/UserStatus';
import _lowerCase from 'lodash/lowerCase';
import _capitalize from 'lodash/capitalize';
import { convertToDate } from '@utils/common';
import BtnAddUser from '@views_admin/users/BtnAddUser';
import { useSelector } from 'react-redux';
import { getProfileSelector } from '@utils/selectors';
import BtnEdit from '@views_admin/users/BtnEdit';
import BtnDelete from '@views_admin/users/BtnDelete';
import _includes from 'lodash/includes';
import BtnLock from '@views_admin/users/BtnLock';
import Sort from '@views_admin/components/Sort';
import Filter from '@views_admin/users/Filter';
import { SortType } from '@apis/enums/SortType';
import SEO from '@components/SEO';

const sortOptions = [
   {
      label: 'Last modified',
      value: 'updated_at',
   },
   {
      label: 'Date created',
      value: 'created_at',
   },
   {
      label: 'Email',
      value: 'email',
   },
   {
      label: 'Username',
      value: 'username',
   },
   {
      label: 'Full name',
      value: 'full_name',
   },
   {
      label: 'Status',
      value: 'status',
   },
];

const Users = memo(() => {
   const { data: userProfile } = useSelector(getProfileSelector);

   const [searchText, setSearchText] = useState('');
   const [filter, setFilter] = useState({
      page: 1,
      pageSize: constant.defaultPagesize,
      orderBy: 'updated_at',
      sortType: SortType.DESC,
      status: '',
      type: '',
      fromDate: '',
      toDate: '',
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
   });
   const [loading, setLoading] = useState(true);
   const cancelToken = useRef();

   const onEditSuccess = useCallback(() => {
      setFilter((prevFilter) => ({
         ...prevFilter,
      }));
   }, []);

   const reloadData = useCallback((keepCurrentPage) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: keepCurrentPage === true ? prevFilter.page : 1,
      }));
   }, []);

   const columns = useMemo(() => {
      return [
         {
            title: 'Username',
            dataIndex: 'username',
            render: (text, record) => (
               <span>
                  <span>{text}</span>
                  {text === userProfile?.username && (
                     <span className="username-this-is-you"> (You)</span>
                  )}
                  {record.seller && <span className="seller-maker">Seller</span>}
               </span>
            ),
         },
         {
            title: 'Email',
            dataIndex: 'email',
         },
         {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => (
               <span className={`user-stt ${text}_${record.active}`}>
                  {(() => {
                     if (text === UserStatus.AVAILABLE) {
                        if (record.active) {
                           return 'Available';
                        }
                        return 'Not activated';
                     }
                     return _capitalize(_lowerCase(text));
                  })()}
               </span>
            ),
         },
         {
            title: 'Role',
            dataIndex: 'type',
            render: (text, record) => (
               <span className={`role-name ${text}`}>
                  {_capitalize(_lowerCase(text))}
               </span>
            ),
         },
         {
            title: 'Created date',
            dataIndex: 'created_at',
            render: (text) => <span>{convertToDate(text)}</span>,
         },
         {
            title: 'Action',
            key: 'action',
            width: 116,
            render: (text, record) => (
               <div className="admin-col-action">
                  <BtnEdit
                     rowData={record}
                     isMe={userProfile?.username === record.username}
                     onSuccess={onEditSuccess}
                  />
                  {userProfile?.username !== record.username && (
                     <>
                        {!_includes([UserStatus.REMOVED], record.status) && (
                           <BtnLock
                              username={record.username}
                              isLocked={record.status === UserStatus.LOCKED}
                              onSuccess={reloadData}
                           />
                        )}
                        {!_includes([UserStatus.LOCKED], record.status) && (
                           <BtnDelete
                              username={record.username}
                              isDeleted={record.status === UserStatus.REMOVED}
                              onSuccess={reloadData}
                           />
                        )}
                     </>
                  )}
               </div>
            ),
         },
      ];
   }, [userProfile, reloadData, onEditSuccess]);

   useEffect(() => {
      if (cancelToken.current) {
         cancelToken.current.cancel();
      }
      setLoading(true);
      cancelToken.current = createCancelToken();
      const loadData = async () => {
         const requestBody = new RequestModel([
            filter,
            {
               search: searchText.trim(),
            },
         ]);
         const responseData = await userService.getUserMatrix(
            requestBody,
            cancelToken.current?.token
         );
         console.log('responseData', responseData);
         if (responseData.isSuccess) {
            const { content, totalRows } = responseData.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
            }));
         }
         setSearchText(searchText.trim());
         setLoading(false);
      };
      loadData();

      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [filter, searchText]);

   const onTableChange = useCallback(({ pagination, sorter }) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: pagination?.page || prevFilter.page,
         pageSize: pagination?.pageSize || prevFilter.pageSize,
      }));
   }, []);

   const handleSort = useCallback((orderBy, sortType) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         sortType,
         orderBy,
      }));
   }, []);

   const handleFilter = useCallback((newFilter = {}) => {
      console.log(newFilter);
      setFilter((prevFilter) => ({
         ...prevFilter,
         ...newFilter,
      }));
   }, []);

   const refresh = useCallback(() => {
      setFilter({
         ...filter,
         orderBy: '',
         sortType: '',
         page: 1,
      });
   }, [filter]);

   const filterSet = useMemo(() => {
      return {
         status: filter.status,
         type: filter.type,
         fromDate: filter.fromDate,
         toDate: filter.toDate,
      };
   }, [filter]);

   return (
      <>
         <SEO title={'Users - Admin'} />
         <div className="content-head">
            <div className="content-head__left">
               <label className="title">Users</label>
            </div>
            <div className="content-head__right">
               <BtnAddUser className="outline-btn btn-add-new-item" onSuccess={refresh}>
                  <i>
                     <IconAdd2 />
                  </i>
                  Add User
               </BtnAddUser>
            </div>
         </div>
         <div className="content-body admin-users__body">
            <div className="admin-table">
               <ContentLoading isLoading={loading} />
               <div className="admin-table__header">
                  <div className="admin-table__header--left">
                     <Search
                        placeholder="Search in users"
                        onSearch={(v) => setSearchText(v)}
                     />
                  </div>
                  <div className="admin-table__header--right">
                     <Filter filterSet={filterSet} onChange={handleFilter} />
                     <Sort
                        orderBy={filter.orderBy}
                        sortType={filter.sortType}
                        options={sortOptions}
                        onChange={handleSort}
                     />
                  </div>
               </div>
               {
                  <CommonTable
                     rowKey="uid"
                     unit="Users"
                     dataSource={data.content}
                     columns={columns}
                     page={filter.page}
                     pageSize={filter.pageSize}
                     totalRows={data.totalRows}
                     onChange={onTableChange}
                  />
               }
            </div>
         </div>
      </>
   );
});

export default Users;
