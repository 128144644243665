import { FETCH_PRODUCTS } from '../constants/actions';
import { dispatch } from '@store/store';
import Request from './utils/Request';

const loadProducts = async (body) => {
   return await dispatch(
      Request.post(FETCH_PRODUCTS, {
         data: body,
      })
   );
};

export const productService = {
   loadProducts,
};
