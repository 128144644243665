import { InputType } from '@utils/enums/InputType';
import { Col, Form, Row } from 'antd';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Input from '@components/Input';
import SelectProducts from '@views_admin/components/SelectProducts';

const EditSeller = memo(
   ({ form, username, sellerInfo, isMe, loading, onSubmitSellerInfo }) => {
      const [states, setStates] = useState({});

      useEffect(() => {
         if (sellerInfo) {
            setStates({
               ...sellerInfo,
            });
         }
      }, [sellerInfo]);

      const onValuesChange = useCallback((values) => {
         console.log(values);
         setStates((prev) => ({ ...prev, ...(values || {}) }));
      }, []);

      const handleSubmit = useCallback(
         async (values) => {
            console.log(values);
            const body = {
               seller: values?.seller || false,
               can_sell_all: values?.can_sell_all || false,
               items: !values?.can_sell_all
                  ? Object.values(values.items || {}).map((i) => ({
                       item_id: i.uid,
                       item_type: 'PRODUCT',
                    }))
                  : [],
            };
            onSubmitSellerInfo(body);
         },
         [onSubmitSellerInfo]
      );

      return (
         <div>
            <Form
               form={form}
               initialValues={{
                  seller: false,
                  can_sell_all: false,
                  items: {},
               }}
               className="submit-form form-edit-seller"
               onFinish={handleSubmit}
               onValuesChange={onValuesChange}
            >
               <Row gutter={8}>
                  <Col lg={7} md={7} sm={24} xs={24}>
                     <label className="submit-form__field-title">Seller</label>
                  </Col>
                  <Col lg={17} md={17} sm={24} xs={24}>
                     <Form.Item name="seller">
                        <Input
                           type={InputType.SWITCH}
                           className="user-editing-input"
                           inputClassName="h40"
                           disabled={isMe && states.seller}
                        />
                     </Form.Item>
                  </Col>
                  {states?.seller && (
                     <>
                        <Col lg={7} md={7} sm={24} xs={24}>
                           <label className="submit-form__field-title">
                              All products
                           </label>
                        </Col>
                        <Col lg={17} md={17} sm={24} xs={24}>
                           <Form.Item name="can_sell_all">
                              <Input
                                 type={InputType.SWITCH}
                                 className="user-editing-input"
                                 inputClassName="h40"
                              />
                           </Form.Item>
                        </Col>
                        {!states?.can_sell_all && (
                           <Col lg={24} md={24} sm={24} xs={24}>
                              <Form.Item name="items">
                                 <SelectProducts />
                              </Form.Item>
                           </Col>
                        )}
                     </>
                  )}
               </Row>
            </Form>
         </div>
      );
   }
);

export default EditSeller;
