import React, { memo, useCallback, useState } from 'react';
import { Tooltip } from 'antd';
import { message } from '../../components/Common/Message';
import { categoryApi } from '../../apis/categoryApi';
import PopConfirm from '@components/PopConfirm';
import { userService } from '@services/userService';
import IconRestore from '@icons/IconRestore';
import IconDelete2 from '@icons/IconDelete2';
import IconDelete from '@icons/IconDelete';

const BtnDelete = memo(({ username, isDeleted, onSuccess }) => {
   const [isModalVisible, setIsModalVisible] = useState(false);

   const showModal = useCallback(() => {
      setIsModalVisible(true);
   }, []);

   const handleOk = useCallback(async () => {
      if (username) {
         setIsModalVisible(false);
         message.loading(isDeleted ? 'Recovering...' : 'Removing...', 0);
         let responseData;
         if (isDeleted) {
            responseData = await userService.recoverUser(username);
         } else {
            responseData = await userService.deleteUser(username);
         }
         setTimeout(async () => {
            if (responseData.isSuccess) {
               message.success(
                  isDeleted ? 'Recover user successfully.' : 'Remove user successfully.'
               );
               if (onSuccess) {
                  onSuccess();
               }
            } else {
               message.error(responseData.message);
            }
         }, 500);
      }
   }, [username, onSuccess, isDeleted]);

   const onPopConfirmVisibleChange = useCallback((isOpen) => {
      setIsModalVisible(isOpen);
   }, []);

   return (
      <>
         <PopConfirm
            isOpen={isModalVisible}
            zIndex={4039}
            title="Are you sure to remove this user?"
            okText="Remove"
            onVisibleChange={onPopConfirmVisibleChange}
            onConfirm={handleOk}
            disabled={isDeleted}
         >
            <Tooltip
               placement="bottom"
               title={isDeleted ? 'Recover' : 'Remove'}
               color="black"
            >
               <button
                  className={`button-icon action-delete ${
                     isModalVisible ? 'active' : ''
                  }`}
                  onClick={() => {
                     if (isDeleted) {
                        handleOk();
                     } else {
                        showModal();
                     }
                  }}
               >
                  {isDeleted ? <IconRestore /> : <IconDelete />}
               </button>
            </Tooltip>
         </PopConfirm>
      </>
   );
});

export default BtnDelete;
