import IconEdit from '@icons/IconEdit';
import { Tooltip, Form } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import EditingDrawer from './EditingDrawer';
import _forIn from 'lodash/forIn';
import { message } from '@components/Message';
import RequestModel from '@apis/models/RequestModel';
import { userService } from '@services/userService';

const BtnEdit = memo(({ rowData, onSuccess, isMe, ...ownProps }) => {
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [form] = Form.useForm();
   const [submitting, setSubmitting] = useState(false);

   const showDrawer = useCallback(() => {
      const valueObj = {};
      _forIn(rowData, (value, key) => {
         if (key === 'country') {
            valueObj.country_code = value?.code || '';
         } else {
            valueObj[key] = value;
         }
      });
      form.setFieldsValue(valueObj);
      setIsDrawerOpen(true);
   }, [form, rowData]);
   const onClose = useCallback(() => {
      setIsDrawerOpen(false);
   }, []);

   useEffect(
      () => {
         const valueObj = {};
         _forIn(rowData, (value, key) => {
            valueObj[key] = value;
         });
         form.setFieldsValue(valueObj);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [rowData]
   );

   const onSubmit = useCallback(
      async (values) => {
         if (rowData.username) {
            await setSubmitting(true);
            const requestBody = new RequestModel(values);
            const responseData = await userService.updateUser(
               rowData.username,
               requestBody
            );
            if (responseData.isSuccess) {
               onSuccess();
               message.success(`You successfully updated user data.`);
               onClose();
            } else {
               message.error(responseData.message);
            }
            setSubmitting(false);
         }
      },
      [onSuccess, rowData.username, onClose]
   );

   const onSubmitSellerInfo = useCallback(
      async (body) => {
         if (rowData.username) {
            if (body.seller) {
               // update seller
               await setSubmitting(true);
               delete body.seller;
               const response = await userService.registerSeller(rowData.username, body);
               if (response.isSuccess) {
                  onSuccess();
                  message.success('Updated seller information successfully');
                  onClose();
               } else {
                  message.error(response.message);
               }
            } else {
               if (rowData.seller) {
                  // remove seller permission
                  const response = await userService.removeSellerPermisison([
                     rowData.username,
                  ]);
                  if (response.isSuccess) {
                     onSuccess();
                     message.success('Removed seller permission.');
                     onClose();
                  } else {
                     message.error(response.message);
                  }
               }
            }
            setSubmitting(false);
         }
      },
      [onSuccess, rowData.username, rowData.seller, onClose]
   );

   return (
      <>
         <Tooltip placement="bottom" title="Edit" color="black">
            <button className="button-icon action-normal" onClick={showDrawer}>
               <IconEdit />
            </button>
         </Tooltip>
         <EditingDrawer
            title={`Edit User`}
            isOpen={isDrawerOpen}
            form={form}
            submitting={submitting}
            currentUid={rowData.username}
            currentRole={rowData.type}
            seller={rowData.seller}
            isMe={isMe}
            onClose={onClose}
            onSubmit={onSubmit}
            onSubmitSellerInfo={onSubmitSellerInfo}
         />
      </>
   );
});

export default BtnEdit;
