import React, { memo, useCallback, useState } from 'react';
import { Form } from 'antd';
import { message } from '@components/Message';
import RequestModel from '@apis/models/RequestModel';
import EditingDrawer from './EditingDrawer';
import { userService } from '@services/userService';

const BtnAddUser = memo(({ className, onSuccess, ...ownProps }) => {
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [form] = Form.useForm();
   const [submitting, setSubmitting] = useState(false);

   const showDrawer = useCallback(() => {
      setIsDrawerOpen(true);
   }, []);
   const onClose = useCallback(() => {
      setIsDrawerOpen(false);
   }, []);

   const onSubmit = useCallback(
      async (values) => {
         await setSubmitting(true);
         const requestBody = new RequestModel({
            ...values,
            username: values?.email || '',
         });
         if (requestBody.is_manager) {
            delete requestBody.password;
            delete requestBody.confirm;
         }
         const responseData = await userService.createUser(requestBody);
         if (responseData.isSuccess) {
            onSuccess();
            message.success('Create new user successfully.');
            onClose();
            form.resetFields();
         } else {
            message.error(responseData.message);
         }
         setSubmitting(false);
      },
      [onSuccess, form, onClose]
   );

   return (
      <>
         <button className={className} onClick={showDrawer}>
            {ownProps.children || 'Add new'}
         </button>

         <EditingDrawer
            title="Create a new User"
            isOpen={isDrawerOpen}
            form={form}
            submitting={submitting}
            onClose={onClose}
            onSubmit={onSubmit}
         />
      </>
   );
});

export default BtnAddUser;
