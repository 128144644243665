import React, {
   Fragment,
   memo,
   useCallback,
   useEffect,
   useMemo,
   useRef,
   useState,
} from 'react';
import { Drawer, Form, Row, Col } from 'antd';
import { InputType } from '@utils/enums/InputType';
import ConditionRow from './ConditionRow';
import _lowerCase from 'lodash/lowerCase';
import _capitalize from 'lodash/capitalize';

import countries from '@utils/countries.json';
import { connect } from 'react-redux';
import IconCancel from '@icons/IconCancel';
import EditSeller from './EditSeller';
import { userService } from '@services/userService';

const countryOptions = countries.data.map((i) => ({
   label: `${i.unicode_flag}  ${i.name}`,
   value: i.code,
}));

const formDefinitions = [
   {
      label: 'Is manager',
      name: 'is_manager',
      type: InputType.SINGLE_CHECKBOX,
   },
   // {
   //    label: 'Username',
   //    name: 'username',
   //    placeholder: 'Username',
   //    rules: [
   //       {
   //          required: true,
   //          message: 'Please input Username.',
   //       },
   //    ],
   //    isRequired: true,
   // },
   {
      label: 'Password',
      name: 'password',
      type: InputType.PASSWORD,
      placeholder: 'Password',
      dependencies: ['is_manager'],
      rules: [
         // {
         //    required: true,
         //    message: 'Please input Password.',
         // },
         ({ getFieldValue }) => ({
            validator: async (_, value) => {
               const isManager = getFieldValue('is_manager');
               if (!isManager && !`${value}`.trim()) {
                  return Promise.reject(new Error('Please input Password.'));
               } else if (isManager) {
                  return Promise.resolve();
               }
               const regex = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/g;
               if (value && !regex.test(value)) {
                  return Promise.reject(
                     new Error(
                        'Password must have at least one letter and one number, contains at least 8 characters.'
                     )
                  );
               }
               return Promise.resolve();
            },
         }),
      ],
      isRequired: true,
   },
   {
      label: 'Confirm Password',
      name: 'confirm',
      type: InputType.PASSWORD,
      placeholder: 'Confirm Password',
      dependencies: ['password', 'is_manager'],
      rules: [
         // {
         //    required: true,
         //    message: 'Please confirm Password.',
         // },
         ({ getFieldValue }) => ({
            validator(_, value) {
               const isManager = getFieldValue('is_manager');
               if (!isManager && !`${value}`.trim()) {
                  return Promise.reject(new Error('Please input Password.'));
               } else if (isManager) {
                  return Promise.resolve();
               }
               if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
               }
               return Promise.reject(new Error('Please make sure passwords match.'));
            },
         }),
      ],
      isRequired: true,
   },
   {
      label: 'Email',
      name: 'email',
      placeholder: 'Email',
      rules: [
         {
            required: true,
            message: 'Please input Email.',
         },
         {
            type: 'email',
            message: 'Please use a valid email address.',
         },
      ],
      isRequired: true,
   },
   {
      label: 'First Name',
      name: 'first_name',
      placeholder: 'First Name',
      rules: [
         {
            required: true,
            message: 'Please input First Name.',
         },
      ],
      isRequired: true,
   },
   {
      label: 'Last Name',
      name: 'last_name',
      placeholder: 'Last Name',
      rules: [
         {
            required: true,
            message: 'Please input Last Name.',
         },
      ],
      isRequired: true,
   },
   {
      label: 'Country',
      name: 'country_code',
      type: InputType.SELECT,
      placeholder: 'Country',
      options: countryOptions,
      showSearch: true,
      allowClear: false,
      autoComplete: 'anyrandomstring',
      filterOption: (input, option) =>
         `${option.label}`.toLowerCase().indexOf(`${input}`.toLowerCase()) >= 0,
      rules: [
         {
            required: true,
            message: 'Please select Country',
         },
      ],
      isRequired: true,
   },
   {
      label: 'Phone number',
      name: 'phone_number',
      placeholder: 'Phone number',
   },
   {
      label: 'Gender',
      name: 'gender',
      placeholder: 'Gender',
      type: InputType.SELECT,
      options: [
         {
            label: 'Male',
            value: 'male',
         },
         {
            label: 'Female',
            value: 'female',
         },
         {
            label: 'Other',
            value: 'other',
         },
      ],
   },
   {
      label: 'Address',
      name: 'street_address',
      placeholder: 'Address',
   },
   {
      label: 'City',
      name: 'city',
      placeholder: 'City',
   },
   {
      label: 'Company',
      name: 'company',
      placeholder: 'Company',
   },
   {
      label: 'Postal Code',
      name: 'postal_code',
      placeholder: 'Postal Code',
   },
];

const TAB_EDIT = 'TAB_EDIT';
const TAB_SELLER = 'TAB_SELLER';

//
const EditingDrawer = memo(
   ({
      isOpen,
      title,
      form,
      submitting,
      currentUid,
      currentRole,
      seller,
      isMe,
      countryData,
      ...ownProps
   }) => {
      const [currentTab, setCurrentTab] = useState(TAB_EDIT);
      const [sellerLoading, setSellerLoading] = useState(false);
      const [sellerInfo, setSellerInfo] = useState(null);
      const cancelToken = useRef();
      const [editSellerForm] = Form.useForm();

      useEffect(() => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      }, []);

      useEffect(() => {
         console.log(seller);
         if (isOpen) {
            if (currentUid && seller && !cancelToken.current) {
               const loadSellterInfo = async () => {
                  await setSellerLoading(true);
                  const responseData = await userService.getSellerDetails(
                     currentUid,
                     cancelToken.current?.token
                  );
                  if (responseData.isSuccess) {
                     console.log(responseData);
                     let items = {};
                     if (responseData.data?.items?.length) {
                        responseData.data.items.forEach((i) => {
                           items[i.uid] = {
                              uid: i.uid,
                              name: i.name,
                           };
                        });
                     }
                     const values = {
                        seller: seller,
                        can_sell_all: responseData.data?.can_sell_all || false,
                        items,
                     };
                     setSellerInfo(values);
                  }

                  setSellerLoading(false);
               };

               loadSellterInfo();
            }
         }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentUid, isOpen, seller, editSellerForm]);

      useEffect(() => {
         editSellerForm.setFieldsValue(sellerInfo);
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [sellerInfo]);

      const handleSubmit = useCallback(() => {
         if (currentTab === TAB_EDIT) {
            form.submit();
         } else {
            editSellerForm.submit();
         }
      }, [currentTab, form, editSellerForm]);

      const fieldList = useMemo(() => {
         let fields = formDefinitions;
         if (isOpen) {
            if (currentUid) {
               // is update
               const fieldsAllowEditing = [
                  'email',
                  'first_name',
                  'last_name',
                  'birthday',
                  'gender',
                  'avatar',
                  'phone_number',
                  'company',
                  'city',
                  'street_address',
                  'postal_code',
                  'country_code',
               ];
               const hiddenFields = ['is_manager', 'password', 'confirm'];
               fields = fields.filter((f) => !hiddenFields.some((i) => i === f.name));
               fields = fields.map((item) => ({
                  ...item,
                  disabled: fieldsAllowEditing.some((i) => i === item.name)
                     ? false
                     : true,
               }));
            }
         }
         return fields;
      }, [currentUid, isOpen]);

      const renderContentEditUser = useCallback(() => {
         return (
            <Form
               form={form}
               initialValues={{
                  country_code: currentUid ? countryData?.data?.country || '' : '',
               }}
               className="submit-form"
               onFinish={ownProps.onSubmit}
            >
               {currentUid && currentRole && (
                  <Row gutter={8}>
                     <Col lg={7} md={7} sm={24} xs={24}>
                        <label className="submit-form__field-title">Role</label>
                     </Col>
                     <Col lg={17} md={17} sm={24} xs={24}>
                        <span className={`submit-form__role ${currentRole}`}>
                           {_capitalize(_lowerCase(currentRole))}
                        </span>
                     </Col>
                  </Row>
               )}
               {fieldList.map((item) => {
                  return (
                     <Fragment key={item.name}>
                        <Form.Item
                           name={item.name}
                           dependencies={item.dependencies}
                           rules={item.rules || []}
                        >
                           <ConditionRow {...item} />
                        </Form.Item>
                     </Fragment>
                  );
               })}
            </Form>
         );
      }, [
         currentUid,
         countryData?.data?.country,
         currentRole,
         fieldList,
         form,
         ownProps.onSubmit,
      ]);

      const handleClose = useCallback(() => {
         if (currentUid && editSellerForm?.resetFields) {
            editSellerForm.resetFields();
            setCurrentTab(TAB_EDIT);
         }
         ownProps.onClose && ownProps.onClose();
      }, [editSellerForm, currentUid, ownProps]);

      return (
         <Drawer
            className="common-drawer drawer-small-editing admin-user-editing-drawer"
            title={
               <div className="common-drawer__header">
                  <div className="admin-user-editing-drawer__tabs">
                     <div
                        className={`tab-item ${currentTab === TAB_EDIT ? 'active' : ''}`}
                        onClick={() => {
                           currentUid && setCurrentTab(TAB_EDIT);
                        }}
                     >
                        {currentUid ? 'Edit user' : 'Create a new User'}
                     </div>
                     {currentUid && (
                        <div
                           className={`tab-item ${
                              currentTab === TAB_SELLER ? 'active' : ''
                           }`}
                           onClick={() => setCurrentTab(TAB_SELLER)}
                        >
                           Seller
                        </div>
                     )}
                  </div>
                  <div className="common-drawer__header--actions">
                     <button
                        className="admin-user-editing-drawer__close-btn"
                        onClick={ownProps.onClose}
                     >
                        <IconCancel />
                     </button>
                  </div>
               </div>
            }
            footer={
               <div className="common-drawer__footer">
                  <div className="common-drawer__footer--actions admin-actions-group">
                     <button className="cancel" onClick={handleClose}>
                        Close
                     </button>
                     <button
                        className="submit"
                        disabled={submitting}
                        onClick={handleSubmit}
                     >
                        {submitting ? 'Saving' : 'Save'}
                     </button>
                  </div>
               </div>
            }
            width={520}
            onClose={handleClose}
            visible={isOpen}
            maskClosable={true}
            closeIcon={null}
            destroyOnClose={true}
         >
            {currentTab === TAB_EDIT ? (
               renderContentEditUser()
            ) : (
               <EditSeller
                  {...ownProps}
                  form={editSellerForm}
                  username={currentUid}
                  sellerInfo={sellerInfo}
                  loading={sellerLoading}
                  isMe={isMe}
                  onSubmitSellerInfo={ownProps.onSubmitSellerInfo}
               />
            )}
         </Drawer>
      );
   }
);

const mappingState = (state, ownProps) => {
   return {
      countryData: state.common.country,
   };
};

export default connect(mappingState)(EditingDrawer);
