import React, { memo, useCallback, useState } from 'react';
import IconDelete from '../../components/Icons/IconDelete';
import { Tooltip } from 'antd';
import { message } from '../../components/Common/Message';
import { userService } from '@services/userService';
import IconLock from '@icons/IconLock';
import IconUnlock from '@icons/IconUnlock';

const BtnLock = memo(({ username, isLocked, onSuccess }) => {
   const handleOk = useCallback(async () => {
      if (username) {
         message.loading(isLocked ? 'Unlocking...' : 'Locking...', 0);
         let responseData;
         if (isLocked) {
            responseData = await userService.unlockUser(username);
         } else {
            responseData = await userService.lockUser(username);
         }
         setTimeout(async () => {
            if (responseData.isSuccess) {
               message.success(
                  isLocked ? 'Unlock user successfully.' : 'Lock user successfully.'
               );
               if (onSuccess) {
                  onSuccess(true);
               }
            } else {
               message.error(responseData.message);
            }
         }, 500);
      }
   }, [username, onSuccess, isLocked]);

   return (
      <>
         <Tooltip placement="bottom" title={isLocked ? 'Unlock' : 'Lock'} color="black">
            <button className="button-icon" onClick={handleOk}>
               {isLocked ? <IconUnlock /> : <IconLock />}
            </button>
         </Tooltip>
      </>
   );
});

export default BtnLock;
