import Input from '@components/Input';
import { Col, Row, Form } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';

const ConditionRow = memo(({ label, isRequired, name, rules, ...props }) => {
   const [visible, setVisible] = useState(true);

   const onIsManagerChange = (isManager) => {
      setVisible(!isManager);
   };

   return (
      <Collapse isOpen={visible}>
         <Row gutter={8}>
            <Col lg={7} md={7} sm={24} xs={24}>
               <label className="submit-form__field-title">
                  {label}
                  {isRequired && <span style={{ color: '#FF5F73' }}> *</span>}
               </label>
            </Col>
            <Col lg={17} md={17} sm={24} xs={24}>
               <Input
                  {...props}
                  name={name}
                  className="user-editing-input"
                  inputClassName="h40"
               />
            </Col>
         </Row>
         {(name === 'password' || name === 'confirm') && (
            <Form.Item name="is_manager">
               <ManagerChecker onIsManagerChange={onIsManagerChange} />
            </Form.Item>
         )}
      </Collapse>
   );
});

const ManagerChecker = memo(({ value, onIsManagerChange }) => {
   useEffect(() => {
      onIsManagerChange(value);
   }, [value, onIsManagerChange]);
   return null;
});

export default ConditionRow;
